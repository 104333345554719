<script>
import Ueditor from './index'
export default {
  extends: Ueditor,
  data() {
    return {
      randomId: 'editor_Mall' + Math.floor(Math.random() * 10000)
    }
  },
  props: {
    appendProduct: String
  },
  watch: {
    appendProduct(newVal) {
      if (newVal) {
        try {
          const products = JSON.parse(newVal)
          products.length
            ? products.forEach(this.addProduct)
            : this.addProduct(products)
        } catch (error) {
          alert('添加商品失败')
        }
      }
    }
  },
  methods: {
    addProduct(product) {
      const productHtml = this.getProductHtml(product)
      this.insertHtml(productHtml)
    },
    getProductHtml(cfg) {
      return `
      <dir style="-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin:0px;padding: 16px;border: 1px solid #e4e4e4;border-radius: 4px;-webkit-box-shadow: 1px 1px 1px #ccc;box-shadow: 1px 1px 1px #ccc;">
        <img src="${cfg.first_pic}" alt="" style="width: 80px;height: 80px;margin-right: 12px;"><dir style="margin:0;padding:0;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;">
          <p style="font-size: 16px;font-weight: bold;line-height: 22px;color: #1d1d1d;">${cfg.title}</p>
          <p style="margin-top: 5px;font-size: 12px;line-height: 17px;color: #6d6d6d;">极飞农业精心制作</p>
          <dir style="margin:0;padding:0;-webkit-box-sizing: border-box;box-sizing: border-box;position: relative;display: -webkit-box;display: -ms-flexbox;display: flex;-webkit-box-align: center;-ms-flex-align: center;align-items: center;margin-top: 10px;-webkit-box-pack: justify;-ms-flex-pack: justify;justify-content: space-between;"><p style="font-size: 16px;color: #da0126;line-height: 22px;-webkit-box-flex: 1;-ms-flex: 1;flex: 1;">￥ ${cfg.price}</p><a href="#/goods?guid=${cfg.guid}" style="padding: 0 11px;line-height: 26px;background-color: #da0126;color: #fff;border-radius: 4px;text-decoration: none;font-size: 14px;">立即购买</a></dir>
        </dir>
      </dir>`
    },
    insertHtml() {},
    scriptReady() {
      if (document.getElementById(this.randomId)) {
        this.addBtn()
        this.ue = window.UE.getEditor(this.randomId, { enableAutoSave: false })
        this.ue.addListener('contentChange', this.onContentChange)
        this.ueReady()
      }
    },
    addBtn() {
      const that = this
      window.UE.registerUI('Button', function(editor, uiName) {
        that.insertHtml = function(val) {
          editor.execCommand('inserthtml', val)
        }
        var btn = new window.UE.ui.Button({
          // 按钮的名字
          name: 'addProduct',
          // 提示
          title: '添加商品',
          // 添加额外样式，指定icon图标，这里默认使用一个重复的icon
          cssRules: '',
          showText: true,
          // 点击时执行的命令
          onclick() {
            // 这里可以不用执行命令,做你自己的操作也可
            that.$emit('addProduct')
          }
        })
        editor.addListener('selectionchange', function() {
          var state = editor.queryCommandState(uiName)
          if (state === -1) {
            btn.setDisabled(true)
            btn.setChecked(false)
          } else {
            btn.setDisabled(false)
            btn.setChecked(state)
          }
        })
        // 因为你是添加button,所以需要返回这个button
        return btn
      })
    }
  }
}
</script>
<style>
.edui-for-addProduct .edui-icon.edui-default {
  background-image: url('../../assets/addProduct.svg') !important;
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
